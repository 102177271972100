import request from '@/utils/request'
export function pagePayOrderTradeVOList(pageCurrent, pageSize, bizUserNo, bizUserName, orderNo, projectId, status, tradeChannel, startCreateDate, endCreateDate, startActualCloseTime, endActualCloseTime, startAccountingTime, endAccountingTime, merchantId, bizDepId, operatorId, balanceOrderTradeOrderNo, businessOrderNo) {
  return request({
    url: '/pay/pay/orderTrade/pagePayOrderTradeVOList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'bizUserNo': bizUserNo,
      'bizUserName': bizUserName,
      'orderNo': orderNo,
      'projectId': projectId,
      'status': status,
      'tradeChannel': tradeChannel,
      'startCreateDate': startCreateDate,
      'endCreateDate': endCreateDate,
      'startActualCloseTime': startActualCloseTime,
      'endActualCloseTime': endActualCloseTime,
      'startAccountingTime': startAccountingTime,
      'endAccountingTime': endAccountingTime,
      'merchantId': merchantId,
      'bizDepId': bizDepId,
      'operatorId': operatorId,
      'balanceOrderTradeOrderNo': balanceOrderTradeOrderNo,
      'businessOrderNo': businessOrderNo
    }
  })
}
export function getTradeStatisticsByMonth(schoolCode, month) {
  return request({
    url: 'pay/pay/orderTrade/getTradeStatisticsByMonth',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode,
      'tradeMonth': month
    }
  })
}
export function getTradeMostUsed(schoolCode, month) {
  return request({
    url: 'pay/pay/orderTrade/getTradeMostUsed',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode,
      'tradeMonth': month
    }
  })
}
export function getTradeMaxAmount(schoolCode, month) {
  return request({
    url: 'pay/pay/orderTrade/getTradeMaxAmount',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode,
      'tradeMonth': month
    }
  })
}
export function getTradeMaxSingleAmount(schoolCode, month) {
  return request({
    url: 'pay/pay/orderTrade/getTradeMaxSingleAmount',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode,
      'tradeMonth': month
    }
  })
}
export function getPayTypeStatistics(schoolCode, month) {
  return request({
    url: 'pay/pay/orderTrade/getPayTypeStatistics',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode,
      'tradeMonth': month
    }
  })
}
export function getTradeStaticsGroupByMonth(schoolCode) {
  return request({
    url: 'pay/pay/orderTrade/getTradeStaticsGroupByMonth',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode
    }
  })
}
export function updateOrderPayType(orderId, payType) {
  return request({
    url: '/pay/web/order/updateOrderPayType',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'orderId': orderId,
      'payType': payType
    }
  })
}
export function getAllProjectShow() {
  return request({
    url: 'pay/web/order/getAllProjectShow',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
