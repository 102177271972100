import request from '@/utils/request'

export function pageListDictType(pageCurrent, pageSize, dictName, dictType, isSys, schoolCode, dataSource) {
  return request({
    url: 'common/com/dict/dictType/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'dictName': dictName,
      'dictType': dictType,
      'isSys': isSys,
      'schoolCode': schoolCode,
      'dataSource': dataSource
    }
  })
}
export function saveDictType(data) {
  return request({
    url: 'common/com/dict/dictType',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function updateDictType(data) {
  return request({
    url: 'common/com/dict/dictType',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function deleteDictType(ids) {
  return request({
    url: 'common/com/dict/dictType/' + ids,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getDictTypes(dataSource, schoolCode) {
  return request({
    url: 'common/com/dict/dictTypes/' + dataSource + '/' + schoolCode,
    method: 'get'
  })
}

export function pageListDictData(pageCurrent, pageSize, dictLabel, dictType, schoolCode, dataSource) {
  return request({
    url: 'common/com/dict/dictData/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'dictLabel': dictLabel,
      'dictType': dictType,
      'schoolCode': schoolCode,
      'dataSource': dataSource
    }
  })
}
export function createDictData(data) {
  return request({
    url: 'common/com/dict/dictData',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function updateDictData(data) {
  return request({
    url: 'common/com/dict/dictData',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function deleteDictData(ids) {
  return request({
    url: 'common/com/dict/dictData/' + ids,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function getDictDatasByTypes(dictTypes) {
  return request({
    url: 'common/com/dict/getDictDatasByTypes/' + dictTypes,
    method: 'get'
  })
}
export function getDictDatasByTypesNotToken(dictType, schoolCode, dataSource) {
  return request({
    url: 'pay/pay/yingXin/getDictDatasByTypesNotToken/' + dictType + '/' + schoolCode + '/' + dataSource,
    method: 'get'
  })
}
