<template>
  <div class="orderList">
    <div class="top">
      <div class="title">{{ $t('order.title') }}</div>
    </div>
    <div class="second">
      <div v-show="mask" class="bg-black" @click="closeSelect" @touchmove.prevent />
      <!-- <van-overlay :show="show" @click="show = false" /> -->
      <div class="search">
        <van-collapse v-model="activeNames">
          <van-collapse-item name="1">
            <template #title>
              <div class="text-color">
                <img v-show="listQuery.startCreateDate===''&&listQuery.endCreateDate===''&&listQuery.showName===''&&listQuery.showStatus===''&&listQuery.projectName===''" :src="searchIcon" alt="" class="searchIcon img">
                <img v-show="!(listQuery.startCreateDate===''&&listQuery.endCreateDate===''&&listQuery.showName===''&&listQuery.showStatus===''&&listQuery.projectName==='')" :src="searchIcon2" alt="" class="searchIcon img" fill="#f00">
                {{ $t('order.filter-query') }}
              </div>
            </template>
            <van-form>
              <div class="border-box">
                <van-row>
                  <van-col :span="8">
                    <div class="clearfix">
                      <img :src="iconList[0]" alt="" class="icon img left">
                      <p class="text-left label left">{{ $t('order.start-data') }}</p>
                    </div>
                  </van-col>
                  <van-col :span="16">
                    <van-field
                      v-model="listQuery.startCreateDate"
                      readonly
                      :placeholder="$t('order.data-input')"
                      :value-class="className"
                      :value="listQuery.startCreateDate"
                      class="picker"
                      @click="showStartMethod"
                    />
                    <van-calendar
                      v-model="startShow"
                      color="#2F9FF2"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :title="$t('order.data-window')"
                      @confirm="confirmPickerStart"
                    />
                  </van-col>
                </van-row>
                <van-row>
                  <van-col :span="8">
                    <div class="clearfix">
                      <img :src="iconList[0]" alt="" class="icon img left">
                      <p class="text-left label left">{{ $t('order.end-data') }}</p>
                    </div>
                  </van-col>
                  <van-col :span="16">
                    <van-field
                      v-model="listQuery.endCreateDate"
                      readonly
                      :placeholder="$t('order.end-input')"
                      :value-class="className"
                      :value="listQuery.endCreateDate"
                      class="picker"
                      @click="showEndMethod"
                    />
                    <van-calendar
                      v-model="endShow"
                      color="#2F9FF2"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :title="$t('order.data-window')"
                      @confirm="confirmPickerEnd"
                    />
                  </van-col>
                </van-row>
                <van-row>
                  <van-col :span="8">
                    <div class="clearfix">
                      <img :src="iconList[1]" alt="" class="icon img left">
                      <p class="text-left label left">{{ $t('order.payment-items') }}</p>
                    </div>
                  </van-col>
                  <van-col :span="16">
                    <van-field
                      clickable
                      name="picker"
                      readonly
                      :value="listQuery.showName"
                      label=""
                      :placeholder="$t('order.payment-input')"
                      class="picker"
                      @click="projectShow = true"
                    />
                    <van-popup v-model="projectShow" position="bottom">
                      <van-picker
                        show-toolbar
                        :columns="searchProjectList"
                        @confirm="onConfirmProject"
                        @cancel="projectShow = false"
                      />
                    </van-popup>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col :span="8">
                    <div class="clearfix">
                      <img :src="iconList[2]" alt="" class="icon img left">
                      <p class="text-left label left">{{ $t('order.order-status') }}</p>
                    </div>
                  </van-col>
                  <van-col :span="16">
                    <van-field
                      clickable
                      name="picker"
                      readonly
                      :value="listQuery.showStatus"
                      label=""
                      :placeholder="$t('order.order-input')"
                      class="picker"
                      @click="statusShow = true"
                    />
                    <van-popup v-model="statusShow" position="bottom">
                      <van-picker
                        show-toolbar
                        :columns="tradeDisplayStatusList"
                        @confirm="onConfirmStatus"
                        @cancel="statusShow = false"
                      />
                    </van-popup>
                  </van-col>
                </van-row>
                <van-row>
                  <van-col :span="8">
                    <div class="clearfix">
                      <img :src="iconList[3]" alt="" class="icon img left">
                      <div class="text-left label left">{{ $t('order.key-word') }}</div>
                    </div>
                  </van-col>
                  <van-col :span="16">
                    <van-field
                      v-model="listQuery.projectName"
                      name="关键字"
                      label=""
                      :placeholder="$t('order.key-input')"
                      class="picker"
                      @blur="pageDown"
                    />
                  </van-col>
                </van-row>
              </div>
              <van-row class="btn-group">
                <van-col :span="12">
                  <van-button type="default" class="btn left reset" @click="reset">{{ $t('main.reset') }}</van-button>
                </van-col>
                <van-col :span="12">
                  <van-button type="info" class="btn right" @click="search">{{ $t('main.screen') }}</van-button>
                </van-col>
              </van-row>
            </van-form>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div id="listLoading">
        <van-loading
          v-if="loading"
          type="spinner"
        />
      </div>
      <van-list
        v-model="isUpLoading"
        class="list"
        :immediate-check="false"
        :finished-text="$t('main.no-more')"
        :finished="upFinished"
        :offset="offset"
        @load="onLoad"
      >
        <div v-if="hidebox">
          <van-row v-for="item in orderList" :key="item.index" type="flex" justify="center" class="item-style" @click="toDetail(item)">
            <van-col :span="4">
              <img v-if="!item.payBizInfoVOList" :src="item.imgUrl" alt="" class="img">
              <img v-if="item.payBizInfoVOList" src="@/assets/icon/merge.png" alt="" class="img">
            </van-col>
            <van-col :span="12" class="middle-text">
              <p v-if="!item.payBizInfoVOList" class="middle-text-first wrapText">{{ item.projectName }}</p>
              <p v-if="item.payBizInfoVOList" class="middle-text-first wrapText">{{ $t('main.combinedPayment') }}</p>
              <p class="middle-text-second">{{ item.orderNo }}</p>
              <p class="middle-text-second gray">{{ item.createDate }}</p>
            </van-col>
            <van-col :span="8">
              <p class="right-first">￥{{ fenToYuan(item.amount) }}</p>
              <p class="middle-text-second" />
              <p class="right-second" :class="[{'font-blue':item.displayStatus==='101'},formatterOrderDisplayStatus(item.displayStatus)==='已付款'?'':'status']">{{ formatterOrderDisplayStatus(item.displayStatus) }}</p>

            <!-- <p v-if="item.status==='已付款'" class="right-second">{{ item.status }}</p>
            <p v-if="item.status==='未付款'" class="right-second status">{{ item.status }}</p> -->
            </van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <van-empty
      v-show="orderList.length===0"
      class="custom-image"
      description="订单列表为空"
    />
    <ReiTabbar v-show="!mask" />
    <v-top />
  </div>
</template>
<script>
import ReiTabbar from './../components/ReiTabbar.vue'
import { Toast, Empty } from 'vant'
import { pageOrderlist
// getOrderById,
  // closeOrderById
} from '@/api/orderlist'
import store from '@/store'
import { getAllProjectShow } from '@/api/orderTrade'
import { getDictDatasByTypes } from '@/api/dict'
import { fenToYuan } from '@/utils/common'
export default {
  name: 'OrderList',
  components: {
    ReiTabbar,
    [Toast.name]: Toast,
    [Empty.name]: Empty
  },
  data() {
    return {
      searchIcon: require('../../assets/newImages/shaixuan.png'),
      searchIcon2: require('../../assets/icon/shaixuan2.svg'),
      user: {
        name: '',
        idserial: ''
      },
      // 状态表单显示
      showStatus: '',
      statusShow: false,
      hidebox: true,
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      loading: false,
      endShow: false,
      startShow: false,
      isLoadingShow: true,
      currentDate: new Date(),
      maxDate: new Date(),
      minDate: new Date(2000, 1, 1),
      className: '',
      // 结束日期显示
      endDateShow: false,
      // 缴费项目显示
      projectShow: false,
      showPicker: false,
      // select数组
      searchProjectList: [],
      // 订单状态
      tradeDisplayStatusList: [],
      listQuery: {
        startCreateDate: '',
        endCreateDate: '',
        projectName: '',
        projectId: '',
        showName: '',
        showStatus: '',
        page: 1,
        limit: 10
      },
      total: 0,
      showCalendar: false,
      activeNames: [],
      orderList: [],
      scroll: '',
      // icon
      iconList: [
        require('../../assets/newImages/orderList1.png'),
        require('../../assets/newImages/orderList2.png'),
        require('../../assets/newImages/orderList3.png'),
        require('../../assets/newImages/orderList0.png')],
      show: false,
      mask: false
    }
  },
  watch: {
    // 监控筛选是否打开显示蒙版
    activeNames(a) {
      if (a.length === 1) {
        this.mask = true
      } else {
        this.mask = false
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.listQuery.page = 1
    this.init()
    this.getDictDatasByTypes()
    this.getAllProjectList()
    Toast.setDefaultOptions({ duration: 300 })
    // this.getOrderById()
    // this.closeOrderById()
  },
  methods: {
    // 键盘收起页面落下
    pageDown() {
      window.scroll(0, 0)
    },
    // 点击蒙版关闭筛选
    closeSelect() {
      this.mask = false
      this.activeNames = []
    },
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 获取字典 订单状态
    getDictDatasByTypes() {
      getDictDatasByTypes('TRADE_DISPLAY_STATUS').then(response => {
        const tempList = response.data.TRADE_DISPLAY_STATUS
        // 给list新增text属性 值为value   只有vant这个框架只接收text
        for (let i = 0; i < response.data.TRADE_DISPLAY_STATUS.length; i++) {
          this.$set(tempList[i], 'text', tempList[i].value)
        }
        this.tradeDisplayStatusList = tempList
        // console.log('list', this.tradeDisplayStatusList)
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    // 初始化
    init() {
      store.dispatch('user/getInfo').then(res => { // 拉取user_info
        this.user.name = res.name
        this.user.idserial = res.idserial
        this.getList()
      }).catch((err) => {
        Toast.fail(err.message)
        store.dispatch('user/logout').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        }).catch(err => {
          Toast.fail(err.message)
        })
      })
    },
    // 缴费项目下拉
    getAllProjectList() {
      getAllProjectShow().then(response => {
        const tempList = response.data
        // 给list新增text属性 值为projectName   只有vant这个框架只接收text
        for (let i = 0; i < response.data.length; i++) {
          this.$set(tempList[i], 'text', tempList[i].projectName)
        }
        this.searchProjectList = tempList
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    // list到底
    onLoad() {
      // console.log(11111)
      this.loading = true
      setTimeout(() => {
        this.listQuery.page = this.listQuery.page + 1
        pageOrderlist(
          this.listQuery.page,
          this.listQuery.limit,
          this.listQuery.projectName,
          this.listQuery.projectId,
          this.listQuery.displayStatus,
          this.listQuery.startCreateDate,
          this.listQuery.endCreateDate,
          window.localStorage.getItem('schoolcode')
        ).then(
          response => {
            if (response.data.records.length === 0) {
              this.upFinished = true
              this.loading = false
              this.isUpLoading = false
              // Toast.fail('没有更多了')
              // this.conheight.background = '#EFF6FF'
              return false
            } else {
              this.orderList = this.orderList.concat(response.data.records)
              this.isUpLoading = false
              this.loading = false
              Toast.success(this.$t('msg.loadSuccess')) // 弹出
            }
          }
        ).catch(err => {
          this.upFinished = true
          this.loading = false
          this.isUpLoading = false
          Toast.fail(err.message)
        })
      }, 500)
    },
    // // 订单状态格式化
    formatterOrderDisplayStatus(displayStatus, column) {
      for (var i = 0; i < this.tradeDisplayStatusList.length; i++) {
        if ((displayStatus + '') === this.tradeDisplayStatusList[i].key) {
          return this.tradeDisplayStatusList[i].value
        }
      }
    },
    // 请求数据
    getList() {
      // console.log('getList')
      this.orderList = []
      this.hidebox = false
      this.loading = true
      setTimeout(() => {
        pageOrderlist(
          this.listQuery.page,
          this.listQuery.limit,
          this.listQuery.projectName,
          this.listQuery.projectId,
          this.listQuery.displayStatus,
          this.listQuery.startCreateDate,
          this.listQuery.endCreateDate,
          window.localStorage.getItem('schoolcode')).then((response) => {
          this.loading = false
          // if (this.user.name === '') {
          //   this.$router.push({ name: 'login' })
          //   console.log('登出')
          //   return false
          // }
          this.orderList = [...this.orderList, ...response.data.records]
          Toast.success(this.$t('msg.loadSuccess'))
          // for (var i = 0; i < this.orderlist.length; i++) {
          //   if (this.orderlist[i].payBizInfoVOList.length > 1) {
          //     console.log('set')
          //     this.$set(this.orderlist[i], 'expandDetail', false)
          //   }
          // }
          this.total = response.data.total
          this.hidebox = true
        }).catch(err => {
          this.loading = false
          Toast.fail(err.message)
        })
      }, 500)
    },
    // 去详情
    toDetail(item) {
      if (item.payBizInfoVOList !== null) {
        const data = JSON.stringify(item)
        const rightData = JSON.stringify(item.payBizInfoVOList)
        this.$router.push({ name: 'orderDetail', query: { order: data, id: item.id, info: rightData }})
      } else {
        const data = JSON.stringify(item)
        this.$router.push({ name: 'orderDetail', query: { order: data, id: item.id }})
      }
    },
    // 筛选
    search() {
      this.upFinished = false

      this.listQuery.page = 1
      this.listQuery.limit = 10
      // 弹回
      this.activeNames = []
      this.orderList = []
      this.getList()
    },
    // 重置
    reset() {
      this.upFinished = false
      this.listQuery.startCreateDate = ''
      this.listQuery.endCreateDate = ''
      this.listQuery.projectId = ''
      this.listQuery.projectName = ''
      this.listQuery.showName = ''
      this.listQuery.showStatus = ''
      this.listQuery.displayStatus = ''
      this.listQuery.page = 1
      this.listQuery.limit = 10
    },
    // 显示开始弹窗
    showStartMethod() {
      this.startShow = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },
    // 显示结束弹窗
    showEndMethod() {
      this.endShow = true
      this.isLoadingShow = true
      setTimeout(() => {
        this.isLoadingShow = false
      }, 500)
    },
    // 确认选择的时间 结束
    confirmPickerEnd(val) {
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      let hour = val.getHours()
      let minute = val.getMinutes()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
      if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
      this.className = 'timeClass'
      this.listQuery.endCreateDate = `${year}-${month}-${day}`
      this.endShow = false
    },
    // 确认选择的时间 开始
    confirmPickerStart(val) {
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      let hour = val.getHours()
      let minute = val.getMinutes()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
      if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
      this.className = 'timeClass'
      this.listQuery.startCreateDate = `${year}-${month}-${day}`
      this.startShow = false
    },
    // 确认状态
    onConfirmStatus(val) {
      // console.log(val)
      this.listQuery.showStatus = val.value
      this.listQuery.displayStatus = val.key
      this.statusShow = false
      // console.log(this.listQuery)
    },
    // 选项格式化函数
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      } else if (type === 'hour') {
        return `${value}时`
      } else if (type === 'minute') {
        return `${value}分`
      } else if (type === 'second') {
        return `${value}秒`
      }
      return value
    },
    // 项目select
    onConfirmProject(value) {
      // 显示
      this.listQuery.showName = value.projectName
      // 实际赋值
      this.listQuery.projectId = value.id
      this.projectShow = false
    },
    // 时间格式化
    formatDate(d) {
      const a = new Date(d)
      const year = a.getFullYear()
      const month = a.getMonth() + 1
      const date = a.getDate()
      const hours = a.getHours()
      const minutes = a.getMinutes()
      const seconds = a.getSeconds()
      const result = `${year}-${this.fillZero(month)}-${this.fillZero(date)} ${this.fillZero(hours)}:${this.fillZero(minutes)}:${this.fillZero(seconds)}`
      return result
    },
    fillZero(n) {
      const result = (n).toString().length === 1 ? ('0' + n) : n
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .custom-image .van-empty__image {
  width: 90px;
  height: 90px;
  margin-top: 100px;
}
.border-box{
  box-shadow: 0px 0px 10px 0px rgba(47, 159, 242, 0.15);
  border-radius: 6px;
  padding: 0 14px;
  ::v-deep .van-field__control{
    text-align: right !important;
  }
}
.wrapText{
  width:90%;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:1;
}
.text-color{
  color:#747474;
  .icon{
    position: absolute;
    top:45%;
    left:37%;
    transform: translate(-50%,-50%);
  }
}
.orderList{
  position: relative;
}
.btn{
  width: 96%;
  border-radius: 4px;
}
.reset{
  color: #2F9FF2;
  border:1px solid rgba(47,159,242,1);
}
.icon{
  margin-top: 2px;
  width: 14px;
  height: 14px;
}
.clearfix{
  height: 40px;
  padding: 12px 0 6px 0;
}
.label{
  color: #2E2E2E;
  font-weight: bold;
  margin-left:4px;
  font-size: 14px;
}
.van-field{
  border: 1px solid #D0D0D0;
  width:100%;
}
.padding-10{
  padding:0 18px;
}
.top{
  height: 84px;
  background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(64,70,166,1) 100%);
  position: relative;
  z-index: 250;
}
.second{
  position: absolute;
  top:64px;
  left:0;
  width: 100%;
  border-radius: 16px 16px 0 0;
  // overflow: hidden;
  background: #f5f5f5;
}
.title{
  text-align: center;
  line-height: 80px;
  font-size:16px;
  color:white;
}

.search{
  width: 100%;
  outline: none;
  border: none;
  margin-bottom: 10px;
  text-align: center;
  height: 44px;
  line-height: 44px;
  font-size:14px;
  color:#747474;
  .van-collapse{
    outline: none;
    border: none;
  }
  ::v-deep .van-collapse-item__content{
    padding-top: 10px;
    // z-index: 100;
  }
}
.list{
  background: #F5F5F5;
  padding-bottom: 50px;
  // min-height: 500px;
}
.item-style{
  background: #fff;
  padding:18px 24px;
  margin-bottom:10px;
  img{
    width: 40px;
    height: 40px;
    border: 1px solid #D0D0D0;
    border-radius: 6px;
  }
}
.middle-text-first{
  height: 22px;
  line-height: 22px;
  font-size:16px;
  color:#2E2E2E;
  margin-bottom: 5px;
  font-weight: 400;
}
.middle-text-second{
  height: 22px;
  font-size:12px;
  line-height: 22px;
  color:#2E2E2E;
}
.middle-text-second.gray{
  color: #747474;
}
.right-first{
  height:22px;
  font-size:22px;
  font-weight:bold;
  color:rgba(46,46,46,1);
  line-height:22px;
  text-align: right;
}
.right-second{
  height: 22px;
  font-size:12px;
  font-weight: 500;
  line-height: 22px;
  color:#747474;
  text-align: right;
}
.status{
  color: #747474;
}
.orderList{
    .van-icon {
        position: absolute;
        right: 35%;
    }
    .van-collapse-item__title{
      border-radius: 16px 16px 0 0 ;
      border:none;
    }
    .van-hairline--top-bottom{
      border:none !important;
    }
    .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
     border-width: 0;
  }
  .van-collapse-item__title{
    background: #f5f5f5;
  }
}
::v-deep .van-collapse-item__title{
  z-index:250;
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 5px 20px;
  width: 90%;
  padding: 5px;
}
 .searchIcon{
   width: 14px;
   height: 14px;
   position: absolute;
   top:50%;
   left:36%;
   transform: translate(-50%,-50%);
 }
::v-deep .van-collapse-item__content{
  padding-top: 8px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: relative;
  top: -1px;
}
.btn-group{
  margin: 20px 0 10px;
  button{
    height: 36px;
  }
}
.picker{
  border: 0;
}
.van-row{
  border-bottom: 1px solid #f5f5f5;
}
.van-row:last-child{
  border-bottom: 0;
}
.label{
  font-weight: normal;
}
.bg-black{
  background: rgba(0, 0, 0, .5);;
  width: 100%;
  height: 1000px;
  position: fixed;
  // z-index: 100;
  bottom: 0;
}
</style>
 <style lang="scss">
 .orderList{
  .van-icon {
      position: absolute;
      right: 35%;
  }
  .van-collapse-item__title{
    border-radius: 15px 15px 0 0 ;
    border:none;
  }
  .van-hairline--top-bottom{
    border:none !important;
  }
  .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
    border-width: 0;
  }
  .van-collapse-item__title{
    background: #fff;
  }
  .van-cell:not(:last-child)::after{
    position: fixed;
  }
}
#listLoading .van-loading{
    position: fixed !important;
    color: #c8c9cc !important;
    font-size: 0;
    vertical-align: middle;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    background: #333333;
    opacity: 0.8;
    border-radius: 10px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
#listLoading .van-loading__spinner {
  color: rgb(255, 255, 255);
  width: 40px !important;
  height: 40px !important;
  margin: 20px !important;
}
.font-blue{
  color: #2F9FF2 !important;
}
</style>

